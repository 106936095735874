export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';



export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_AUTH_COMPLETE = 'SET_AUTH_COMPLETE';


// events
export const FETCH_EVENTS = "FETCH_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const FETCH_SCHOOL_WIDE_EVENTS = "FETCH_SCHOOL_WIDE_EVENTS";

// students
export const ADD_STUDENT = "ADD_STUDENT";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const REMOVE_STUDENT = "REMOVE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";

