import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyB0SDmfCxsp8i6Qy24ICcUZvYBSU69HDpE",
    // authDomain: "project-locus-b23e9.firebaseapp.com",
    authDomain: "projectlocust.fitalyst.com",
    databaseURL: "https://project-locus-b23e9.firebaseio.com",
    projectId: "project-locus-b23e9",
    storageBucket: "project-locus-b23e9.appspot.com",
    messagingSenderId: "336662450914",
    appId: "1:336662450914:web:b229c515121cd824dc84bb",
    measurementId: "G-Z7XFHZM377"
  };

  const fire = firebase.initializeApp(firebaseConfig);

  export default fire;
