import { combineReducers } from 'redux';
import defaultReducer from './reducer';
import eventsReducer from './events';
import { firebaseReducer } from "react-redux-firebase";
import authReducer from './authReducer'

export default combineReducers({
    defaultReducer,
    firebaseReducer,
    authReducer,
    eventsReducer
})