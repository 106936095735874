import * as actionTypes from './actionTypes'
import {
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    SIGNIN_SUCCESS,
    SIGNIN_ERROR,
    SIGNOUT_SUCCESS,
    SIGNOUT_ERROR,
    ADD_STUDENT,
    FETCH_STUDENTS,
    REMOVE_STUDENT,
    UPDATE_STUDENT
} from './actionTypes'
import firebase from '../../config/fire'


let db = firebase.firestore();
export const signIn = (userId) => {
    return {
        type: actionTypes.SIGN_IN,
        payload: {
            userId
        }
    }
}

export const signOut = () => {
    return {
        type: actionTypes.SIGN_OUT
    }
}


export const addStudent = (uid, student) => async dispatch => {

    const { name, email, major, gradYear, status, id } = student;

    try {
        let res = await db.collection("Users").doc(uid).collection("Students").doc(id).set({
            name,
            email,
            major,
            gradYear,
            status
        })
        console.log('res = ' + res);
        dispatch({
            type: ADD_STUDENT,
            payload: student
        })
    } catch (err) {
        console.error("Error writing document: ", err);
        dispatch({
            type: 'error'
        })
    }
}

export const fetchStudents = (uid) => async dispatch => {
    let fetchedStudents = [];

    // look inside 'Users' collection, get RA based on uid, get their events out of subcollection
    try {
        let querySnapshot = await db.collection("Users").doc(uid).collection("Students").get();
        querySnapshot.forEach(doc => {
            var student = doc.data();
            student.id = doc.id;
            fetchedStudents.push(student)
        });
    } catch (err) {
        console.log(err)
    }

    dispatch({
        type: FETCH_STUDENTS,
        payload: fetchedStudents
    })
}

export const removeStudent = (uid, studentId) => async dispatch => {

    await db.collection("Users").doc(uid).collection("Students").doc(studentId).delete().then(function() {
        dispatch({
            type: REMOVE_STUDENT,
            payload: studentId
        })
    }).catch(function(error) {
        console.error("Error removing student: ", error);
        dispatch({
            type: 'error'
        })
    });
}

export const updateStudent = (uid, student) => async dispatch => {

    const { name, email, major, gradYear, status, id } = student;

    await db.collection("Users").doc(uid).collection("Students").doc(id).set({
        name,
        email,
        major,
        gradYear,
        status
    }).then(function() {
        dispatch({
            type: UPDATE_STUDENT,
            payload: student
        })
    }).catch(function(error) {
        console.error("Error removing student: ", error);
        dispatch({
            type: 'error'
        })
    });
}
