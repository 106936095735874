import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import { signIn, signOut } from '../store/actions/actions'

// Client ID and API key from the Developer Console
// const CLIENT_ID = '968408382680-fpc3dou2vdtq2rqmn5tauavr52jv60oq.apps.googleusercontent.com'
// // const API_KEY = 'AIzaSyB9Ftvs1h-_rITuo1hx_R6Nyul4QFx-_Xg'
// const API_KEY = 'AIzaSyB0SDmfCxsp8i6Qy24ICcUZvYBSU69HDpE'
// // Array of API discovery doc URLs for APIs used by the quickstart
// const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
// // Authorization scopes required by the API; multiple scopes can be
// // included, separated by spaces.
// const SCOPES = "email https://www.googleapis.com/auth/calendar.events"


const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const SignIn = Loadable({
    loader: () => import('../Demo/Authentication/SignIn/SignIn1'),
    loading: Loader
});

class App extends Component {
    state = {
        authPending: true
    }

    componentWillMount() {
        // window.gapi.load('client:auth2', () => {
        //     window.gapi.client.init({
        //         apiKey: API_KEY,
        //         clientId: CLIENT_ID,
        //         discoveryDocs: DISCOVERY_DOCS,
        //         scope: SCOPES   
        //     }).then(() => {
        //         // after async init function resolves, get reference to init object
        //         this.auth = window.gapi.auth2.getAuthInstance();
        //         this.onAuthChange(this.auth.isSignedIn.get());

        //         // this function listens to any changes in sign-in status
        //         // callback is invoked when status changes
        //         this.auth.isSignedIn.listen(this.onAuthChange);
        //         this.setState({ authPending: false })
        //     }).catch((err) => {
        //         console.log(err);
        //     })
        // }) 
    }

    onAuthChange = (isSignedIn) => {
        if (isSignedIn) {
            this.props.signIn(this.auth.currentUser.get().getId(), this.props.history);
        } else {
            this.props.signOut();
        } 
    }

    render() {
        const menu = routes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });

        // if (this.props.isSignedIn == null && this.props.userId == null) {
        //     return <Loader />
        // }

        // if (!this.props.isSignedIn) {
        if (!this.props.userId) {
            return (
                <Aux>
                    <ScrollToTop>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route path="/" component={SignIn} />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                </Aux>
            )
        }

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {menu}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}


const mapStateToProps = state => {
    return { 
        isSignedIn: state.authReducer.isSignedIn,
        userId: state.authReducer.userId,
    }
}

export default withRouter(connect(mapStateToProps, { signIn, signOut }, null, { pure: false })(App));
