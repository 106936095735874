import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// const LandingPage = React.lazy(() => import('./App/components/LandingPage'));
const Dashboard = React.lazy(() => import('./App/components/Dashboard'));
const Students = React.lazy(() => import('./App/components/Students'));
const Events = React.lazy(() => import('./App/components/Events'))
const SchoolStudent = React.lazy(() => import('./Demo/Panels/School/Student'));
const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));



const routes = [
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard, isPrivate: true },
    // { path: '/landing-page', exact: true, name: 'Landing Page', component: LandingPage, isPrivate: true },
    { path: '/students', exact: true, name: 'Students', component: Students, isPrivate: true },
    { path: '/events', exact: true, name: 'Events', component: Events, isPrivate: true },

    { path: '/auth/signup', exact: true, name: 'Signup 1', component: SignUp1, isPrivate: false },
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: Signin1, isPrivate: false },
    // { path: '/sample-page', exact: true, name: 'Sample Page', component: OtherSamplePage, isPrivate: true },
    // { path: '/school/sch-student', exact: true, name: 'School Student', component: SchoolStudent, isPrivate: true },

];

export default routes;