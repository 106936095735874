import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect, withRouter, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Fullscreen from "react-full-screen";
import windowSize from 'react-window-size';

import Navigation from './Navigation';
import NavBar from './NavBar';
import Configuration from './Configuration';
import Loader from "../Loader";
import routes from "../../../routes";
import Aux from "../../../hoc/_Aux";
import * as actionTypes from "../../../store/actions/actionTypes";



//import '../../../app.scss';

class AdminLayout extends Component {

    fullScreenExitHandler = () => {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            this.props.onFullScreenExit();
        }
    };

    UNSAFE_componentWillMount() {
        if (this.props.windowWidth > 992 && this.props.windowWidth <= 1024 && this.props.layout !== 'horizontal') {
            this.props.onUNSAFE_componentWillMount();
        }
    }

    mobileOutClickHandler() {
        if (this.props.windowWidth < 992 && this.props.collapseMenu) {
            this.props.onUNSAFE_componentWillMount();
        }
    }

    render() {

        /* full screen exit call */
        document.addEventListener('fullscreenchange', this.fullScreenExitHandler);
        document.addEventListener('webkitfullscreenchange', this.fullScreenExitHandler);
        document.addEventListener('mozfullscreenchange', this.fullScreenExitHandler);
        document.addEventListener('MSFullscreenChange', this.fullScreenExitHandler);



        const components = routes.map((route, index) => {
            console.log('route name: ' + route.name)
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });

        let mainClass = ['pcoded-wrapper'];
        if (this.props.layout === 'horizontal' && this.props.subLayout === 'horizontal-2') {
            mainClass = [...mainClass, 'container'];
        }


        // if (!this.props.auth.isLoaded && this.props.auth.isEmpty) {
        //     return <Loader />
        // } else {
            return (
                <Aux>
                    <Fullscreen enabled={this.props.isFullScreen}>
                        <Navigation />
                        <NavBar />
                        <div className="pcoded-main-container" onClick={() => this.mobileOutClickHandler}>
                            <div className={mainClass.join(' ')}>
                                <div className="pcoded-content">
                                    <div className="pcoded-inner-content">
                                        <div className="main-body">
                                            <div className="page-wrapper">
                                                <Suspense fallback={<Loader />}>
                                                        <Switch>
                                                            {components}
                                                            <Redirect from="/" to={this.props.defaultPath} />
                                                        </Switch>
                                                </Suspense>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Configuration />
                    </Fullscreen>
                </Aux>
            );
        }
    }


const mapStateToProps = state => {
    return {
        defaultPath: state.defaultReducer.defaultPath,
        isFullScreen: state.defaultReducer.isFullScreen,
        collapseMenu: state.defaultReducer.collapseMenu,
        layout: state.defaultReducer.layout,
        subLayout: state.defaultReducer.subLayout,
        isSignedIn: state.defaultReducer.isSignedIn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFullScreenExit: () => dispatch({ type: actionTypes.FULL_SCREEN_EXIT }),
        onUNSAFE_componentWillMount: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(AdminLayout));
