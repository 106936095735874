import * as actionTypes from '../actions/actionTypes'


const initialState = {
    allEvents: [],
    upcomingEvents: [],
    pastEvents: [],
    events: [],
    schoolWideEvents: [],
    eventDrafts: []
};

let eventsReducer =  (state = initialState, action) => {
    switch (action.type) {        
        case (actionTypes.FETCH_EVENTS):
            console.log('EVENTS REDUCER: FETCH EVENTS')
            return {
                ...state,
                allEvents: action.payload.allEvents,
                upcomingEvents: action.payload.upcomingEvents,
                pastEvents: action.payload.pastEvents,
                eventDrafts: action.payload.eventDrafts
            }

        case (actionTypes.ADD_EVENT):
            console.log('EVENTS REDUCER: ADD EVENT');
            let updatedEvents = [...state.allEvents];
            let updatedUpcomingEvents = [...state.upcomingEvents]
            let updatedEventDrafts = [...state.eventDrafts]
            
            updatedEvents.push(action.payload); 
            updatedUpcomingEvents.push(action.payload);
            if (action.payload.isDraft) {
                updatedEventDrafts.push(action.payload)
            }
            

            return  {...state, 
                    allEvents: updatedEvents,
                    upcomingEvents: updatedUpcomingEvents,
                    eventDrafts: updatedEventDrafts }

        case (actionTypes.FETCH_SCHOOL_WIDE_EVENTS):
            console.log('EVENTS REDUCER: FETCH SCHOOL WIDE EVENTS')
            return {...state, schoolWideEvents: action.payload}

        default:
            return state;
    }
};

export default eventsReducer;
