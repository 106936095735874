import React from 'react';
import AdminLayout from './App/layout/AdminLayout';

// const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
const SignIn1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));



const route = [
    // when these objects are commented out, navigating to these route renders the components as content within the dashboard (side menu is present)
    // including these objects brings you to a a page containing ONLY that component 
    // { path: '/auth/signup', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: SignIn1 },
    // { path: '/', exact: true, name: AdminLayout, component: AdminLayout}

];

export default route;